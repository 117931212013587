import React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Typography
} from '@mui/material';
import { Link } from 'react-router-dom';
import { isExternal } from 'utility';

const Sidebar = ({ handleDrawerToggle, handleLogout, navigations, isAuthenticated }) => {
  return (
    <Box onClick={handleDrawerToggle} className="sidebar">
      <Link to="/">
        <Typography variant="h6" color="primary" sx={{ my: 2, px: 2 }} className="sidebar__logo">
          AIICO MULTISHIELD
        </Typography>
      </Link>

      <Divider />

      <List className="sidebar__list">
        {Object.keys(navigations).map((item) => (
          <ListItem key={item} disablePadding>
            <ListItemButton sx={{ px: 2 }}>
              {isExternal(navigations[item]) ? (
                <a href={navigations[item]} target="_blank" rel="noreferrer">
                  <ListItemText primary={item} className="color-primary" />
                </a>
              ) : (
                <Link to={navigations[item]}>
                  <ListItemText primary={item} className="color-primary" />
                </Link>
              )}
            </ListItemButton>
          </ListItem>
        ))}
        {isAuthenticated ? (
          <ListItem
            disablePadding
            onClick={() => {
              handleLogout();
            }}
          >
            <ListItemButton sx={{ px: 2 }}>
              {/* <Link to="/welcome" className="color-secondary"> */}
              <ListItemText primary="Logout" className="color-secondary" />
              {/* </Link> */}
            </ListItemButton>
          </ListItem>
        ) : (
          <ListItem disablePadding>
            <ListItemButton sx={{ px: 2 }}>
              <Link to="/welcome" className="color-secondary">
                <ListItemText primary="Agent Login" />
              </Link>
            </ListItemButton>
          </ListItem>
        )}
      </List>
    </Box>
  );
};

Sidebar.propTypes = {
  handleDrawerToggle: PropTypes.func,
  handleLogout: PropTypes.func,
  navigations: PropTypes.object,
  isAuthenticated: PropTypes.bool
};

export default Sidebar;
