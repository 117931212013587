/* eslint-disable */
import { createSlice } from '@reduxjs/toolkit';
import IMAGES from 'utility/images';
import fetch from 'utility/fetch';

export const product = createSlice({
  name: 'product',
  initialState: {
    loading: false,
    products: [],
    category: [],
    hospitals: [],
    mappings: {},
    states: [],
    reg_completed: false
  },
  reducers: {
    update: (state, action) => {
      state.products = action.payload.product;
      state.category = action.payload.category;
    },
    updateRegistrationStatus: (state, action) => {
      state.reg_completed = action.payload;
    },
    updateHospitalList: (state, action) => {
      state.mappings = action.payload.mappings;
      state.hospitals = action.payload.hospitals;
      let states = [];
      for (let index = 0; index < action.payload.hospitals.length; index++) {
        const element = action.payload.hospitals[index];
        if (!states.includes(element.state)) {
          states.push(element.state);
        }
      }
      state.states = states;
    },
    setLoading(state, action) {
      state.loading = action.payload;
    }
  }
});

export const updateProducts = () => async (dispatch, getState) => {
  dispatch(setLoading(true));

  try {
    let temp = await fetch('get_products');

    if (temp.data.status) {
      dispatch(update(temp.data.payload));
    }
  } catch (error) {
    // TODO: handle error
  } finally {
    dispatch(setLoading(false));
  }
};

export const loadMapping = () => async (dispatch, getState) => {
  dispatch(setLoading(true));

  try {
    let temp = await fetch('get_hospitals');

    if (temp.data.status) {
      dispatch(updateHospitalList(temp.data.payload));
    }
  } catch (error) {
    // TODO: handle error
    console.log(error);
  } finally {
    dispatch(setLoading(false));
  }
};
export const { update, setLoading, updateHospitalList, updateRegistrationStatus } = product.actions;
export const selectProduct = (state) => state.product.products;
export default product.reducer;
