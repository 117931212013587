import IMAGES from './images';

export const DRAWER_WIDTH = 240;

export const NAV_ITEMS = {
  Home: '/',
  About: '/',
  Contact: '/'
};

// ['Home', 'About', 'Contact'];

export const FOOTER_NAV = [
  {
    title: 'Service',
    list: [
      {
        name: 'Standard',
        url: 'https://www.aiicomultishield.com/index.php/retail/standard-plan'
      },
      {
        name: 'Executive Plan',
        url: 'https://www.aiicomultishield.com/index.php/retail/executive-plan'
      },
      {
        name: 'Super Executive',
        url: 'https://www.aiicomultishield.com/index.php/corporate/executive-corporate-plan'
      },
      {
        name: 'Magnum Plan',
        url: 'https://www.aiicomultishield.com/index.php/retail/magnum-plan'
      }
    ]
  },
  {
    title: 'About',
    list: [
      {
        name: 'About Us',
        url: 'https://www.aiicomultishield.com/index.php/about/about-us'
      },
      {
        name: 'Executives',
        url: 'https://www.aiicomultishield.com/index.php/home/leadership/b-o-d-exec-mgt'
      },
      {
        name: 'B.O.D',
        url: 'https://aiicomultishield.com/index.php/home/leadership/board-of-directors'
      },
      {
        name: 'News',
        url: 'https://www.aiicomultishield.com/index.php/home/our-media/news'
      }
    ]
  },
  {
    title: 'Quick links',
    list: [
      {
        name: 'Hospital Access',
        url: 'https://www.aiicomultishield.com/index.php/hospital-access/standard-hospital-access'
      },
      {
        name: 'Who Is A Provider?',
        url: 'https://www.aiicomultishield.com/index.php/support/who-is-a-provider'
      },
      {
        name: 'Plan Comparison',
        url: 'https://www.aiicomultishield.com/index.php/retail/compare-plans'
      },
      {
        name: 'Quality Assurance',
        url: 'https://www.aiicomultishield.com/index.php/support/quality-assurance'
      }
    ]
  },
  {
    title: 'Help service',
    list: [
      {
        name: 'Contact Us',
        url: 'https://www.aiicomultishield.com/index.php/home/contact-us'
      },
      {
        name: 'Contact Our Branches',
        url: 'https://aiicomultishield.com/index.php/home/business-location'
      },
      {
        name: 'Inspection-Checklist',
        url: 'https://www.aiicomultishield.com/index.php/support/customer-feedback-form'
      },
      {
        name: 'Change Provider',
        url: 'https://aiicomultishield.com/index.php/support/change-provider'
      },
      {
        name: 'Privacy Policy',
        url: 'https://www.aiicomultishield.com/index.php/help-service/privacy-policy'
      }
    ]
  }
];

export const ALL_PRODUCTS = [
  {
    id: 1,
    title: 'Doctor Dey',
    image: IMAGES.home.doctordey,
    isMostViewed: true,
    url: '/product/doctor-dey',
    category: 'health'
  },
  {
    id: 2,
    title: 'Product Name',
    image: IMAGES.home.product2,
    isMostViewed: false,
    url: '/',
    category: 'product b'
  },
  {
    id: 3,
    title: 'Product Name',
    image: IMAGES.home.product3,
    isMostViewed: false,
    url: '/',
    category: 'product c'
  },
  {
    id: 4,
    title: 'Product Name',
    image: IMAGES.home.product4,
    isMostViewed: false,
    url: '/',
    category: 'others'
  },
  {
    id: 5,
    title: 'Product Name',
    image: IMAGES.home.product5,
    isMostViewed: false,
    url: '/',
    category: 'health'
  },
  {
    id: 6,
    title: 'Product Name',
    image: IMAGES.home.product6,
    isMostViewed: false,
    url: '/',
    category: 'product b'
  }
];

export const TITLES = ['Mr', 'Master', 'Miss', 'Mrs', 'Doctor', 'Chief'];
export const GENDER = ['Male', 'Female', 'Others'];

export const CUSTOMER_INFO = {
  email: '',
  title: '',
  firstName: '',
  lastName: '',
  phone: '',
  gender: '',
  address: ''
};
export const ENROLLEE_INFO = {
  email: '',
  title: '',
  firstName: '',
  lastName: '',
  phone: '',
  gender: '',
  address: '',
  dob: ''
};
export const PRODUCT_INFO = {
  product: '',
  health_history: '_',
  no_dependants: '0',
  dob: ''
};

export const TRANSACTIONS = [
  {
    id: 'transaction-01',
    title: 'Commision from #10321',
    status: 'completed',
    date: 'Mar 21, 2019, 3:30pm',
    commission: '34250.00',
    product: 'Doctor Day',
    customer: 'Jane Baba Isale'
  },
  {
    id: 'transaction-02',
    title: 'Commision from #00910',
    status: 'completed',
    date: 'Mar 21, 2019, 3:30pm',
    commission: '2016.50',
    product: 'Doctor Day',
    customer: 'Jane Baba Isale'
  },
  {
    id: 'transaction-03',
    title: 'Withdraw from Wallet',
    status: 'withdraw',
    date: 'Mar 21, 2019, 3:30pm',
    commission: '23444',
    product: 'Doctor Day',
    customer: 'Ngozi Salami'
  },
  {
    id: 'transaction-04',
    title: 'Payment #023328',
    status: 'completed',
    date: 'Mar 21, 2019, 3:30pm',
    commission: '3250.00',
    product: 'Doctor Day',
    customer: 'Jane Baba Isale'
  },
  {
    id: 'transaction-05',
    title: 'Failed from #087651',
    status: 'declined',
    date: 'Mar 21, 2019, 3:30pm',
    commission: '2500.00',
    product: 'Doctor Day',
    customer: 'Jane Baba Isale'
  }
];
