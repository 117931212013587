import { lazy } from 'react';

const generalRoutes = [
  {
    path: '',
    element: lazy(() => import('pages/Home')),
    layout: true,
    permission: ''
  },
  {
    path: '404',
    element: lazy(() => import('pages/NotFound')),
    layout: false,
    permission: ''
  },
  {
    path: 'welcome',
    element: lazy(() => import('pages/Welcome')),
    layout: true,
    permission: ''
  },
  {
    path: 'product/:name',
    element: lazy(() => import('pages/Product')),
    layout: true,
    permission: ''
  }
];

export default generalRoutes;
