import React from 'react';
import RouterComponent from 'routes';
import { createTheme, ThemeProvider, StyledEngineProvider } from '@mui/material';

const theme = createTheme({
  typography: {
    fontFamily: 'Sarabun'
  },
  palette: {
    primary: {
      main: '#29166f'
    },
    secondary: {
      main: '#ff4040'
    },
    success: {
      main: '#27ae60'
    }
  }
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <StyledEngineProvider injectFirst>
        <RouterComponent />
      </StyledEngineProvider>
    </ThemeProvider>
  );
}

export default App;
