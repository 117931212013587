import DefaultLayout from 'layouts';
import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate, useLocation } from 'react-router-dom';
import agentRoutes from './agent';
import customerRoutes from './customer';
import generalRoutes from './general';

const ScrollToTop = () => {
  const { pathname } = useLocation();

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

const RouterComponent = () => {
  return (
    <Router>
      <ScrollToTop />

      <Routes>
        {/* general routes */}
        <Route path="/" element={<DefaultLayout />}>
          {generalRoutes.map(({ path, element: Element }) => {
            return <Route key={path} path={path} element={<Element />} />;
          })}
        </Route>

        {/* agent routes */}
        <Route path="/agent" element={<DefaultLayout />}>
          {agentRoutes.map(({ path, element: Element }) => {
            return <Route key={path} path={path} element={<Element />} />;
          })}
        </Route>

        {/* customer routes */}
        <Route path="/customer" element={<DefaultLayout />}>
          {customerRoutes.map(({ path, element: Element }) => {
            return <Route key={path} path={path} element={<Element />} />;
          })}
        </Route>

        <Route path="*" element={<Navigate to="/404" />} />
      </Routes>
    </Router>
  );
};

export default RouterComponent;
