import doctordey from 'assets/images/products/doctor-dey.png';
import product2 from 'assets/images/products/product-2.png';
import product3 from 'assets/images/products/product-3.png';
import product4 from 'assets/images/products/product-4.png';
import product5 from 'assets/images/products/product-5.png';
import product6 from 'assets/images/products/product-6.png';

import header from 'assets/images/header.png';

import advert from 'assets/images/advert.jpg';

const IMAGES = {
  default: {
    header,
    advert
  },
  home: {
    doctordey,
    product2,
    product3,
    product4,
    product5,
    product6
  }
};

export default IMAGES;
