import React from 'react';
import { Grid, Link, List, ListItem, Typography } from '@mui/material';
import { FOOTER_NAV } from 'utility/constants';

const FooterGrid = () => {
  return (
    <Grid
      container
      spacing={2}
      className="footer__mb footer__nav"
      sx={{ display: { xs: 'none', sm: 'flex' } }}
    >
      {FOOTER_NAV.map((item, i) => (
        <Grid item xs={3} key={i}>
          <List className="footer__list">
            <ListItem className="mb-3">
              <Typography variant="h6" color="primary" className="fs-sm text-uppercase">
                {item.title}
              </Typography>
            </ListItem>

            {item.list.map((navItem, j) => (
              <ListItem key={j}>
                <Link href={navItem.url}>{navItem.name}</Link>
              </ListItem>
            ))}
          </List>
        </Grid>
      ))}
    </Grid>
  );
};

export default FooterGrid;
