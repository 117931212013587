import { lazy } from 'react';

const agentRoutes = [
  {
    path: '',
    element: lazy(() => import('pages/agent/Login')),
    layout: true,
    permission: ''
  },
  {
    path: 'login',
    element: lazy(() => import('pages/agent/Login')),
    layout: true,
    permission: ''
  },
  {
    path: 'dashboard',
    element: lazy(() => import('pages/agent/Dashboard')),
    layout: true,
    permission: ''
  },
  {
    path: 'customers',
    element: lazy(() => import('pages/agent/customers')),
    layout: true,
    permission: ''
  },
  {
    path: 'customers/enroll',
    element: lazy(() => import('pages/agent/customers/Enroll')),
    layout: true,
    permission: ''
  },
  {
    path: 'wallet/topup',
    element: lazy(() => import('pages/agent/wallet/Topup')),
    layout: true,
    permission: ''
  },
  {
    path: 'wallet/details',
    element: lazy(() => import('pages/agent/wallet/TopupConfirm')),
    layout: true,
    permission: ''
  },
  {
    path: 'transactions/:ut/:id',
    element: lazy(() => import('pages/agent/transactions/Details')),
    layout: true,
    permission: ''
  },
  {
    path: 'transactions',
    element: lazy(() => import('pages/agent/transactions')),
    layout: true,
    permission: ''
  }
];

export default agentRoutes;
