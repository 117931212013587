/* eslint-disable no-restricted-globals */
/* eslint-disable no-param-reassign */
// this file is going to contain configure axios both for authentication need or Notification

import axios from 'axios';

const key = '120138ed-86b8-4ef4-9fb1-d8a1d270bcc6';
axios.interceptors.request.use(
  (config) => {
    config.headers['X-API-KEY'] = key;
    const token = localStorage.getItem('tktk');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  null,
  { synchronous: true }
);

//   include response interceptor
axios.interceptors.response.use(
  null,
  (error) => {
    if (error?.response?.data?.error_code === '401') {
      sessionStorage.clear();
      location.assign('/');
    }
    return Promise.reject(error);
  },
  { synchronous: true }
);
export default axios;
