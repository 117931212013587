import React, { Suspense, useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Footer from 'layouts/footer';
import { useSelector, useDispatch } from 'react-redux';
import { loadProfile, clearNotice } from 'store/reducers/profile';
import { loadPayment } from 'store/reducers/payment';
import Loader from 'components/Loader';
import { Box, Typography } from '@mui/material';
import { today } from 'utility';
import Header from './Header';

const DefaultLayout = ({ className }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(clearNotice());
    dispatch(loadProfile());
    if (localStorage.getItem('tktk')) {
      dispatch(loadPayment());
    }
  }, [dispatch]);

  const isAuthenticated = useSelector((state) => state.profile.isAuthenticated);
  return (
    <main className={classnames('page-body', className)}>
      <Header isAuthenticated={isAuthenticated} />

      <div
        className={classnames('page-inner', {
          'page--no-footer': isAuthenticated
        })}
      >
        <Suspense fallback={<Loader />}>
          <Outlet />
        </Suspense>
      </div>

      {!isAuthenticated ? (
        <Footer />
      ) : (
        <Box component="footer" sx={{ paddingY: 2 }}>
          <Typography component="p" variant="p" textAlign="center" className="fs-sm">
            &copy; <span>{today}</span> AIICO Multishield Limited. All rights reserved.
          </Typography>
        </Box>
      )}
    </main>
  );
};

DefaultLayout.propTypes = {
  className: PropTypes.string
};

export default DefaultLayout;
