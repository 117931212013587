import { Box, Container, Link, Stack, Typography } from '@mui/material';
import React from 'react';
import { today } from 'utility';

const Copyright = () => {
  return (
    <Box className="footer__bottom">
      <Container>
        <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
          <Typography variant="p">
            &copy; <span>{today}</span> AIICO Multishield Limited. All rights reserved.
          </Typography>

          <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
            <Link href="https://www.aiicomultishield.com/index.php/help-service/privacy-policy">
              Privacy and Security
            </Link>
            <Link href="https://www.aiicomultishield.com/index.php/help-service/privacy-policy">
              Terms of Use
            </Link>
          </Stack>
        </Stack>
      </Container>
    </Box>
  );
};

export default Copyright;
