export const slugify = (str) =>
  str
    .toLowerCase()
    .trim()
    .replace(/[^\w\s-]/g, '')
    .replace(/[\s_-]+/g, '-')
    .replace(/^-+|-+$/g, '');

export const unSlugify = (str) => str.split('-').join(' ');

export const a11yProps = (index, title, arialabel) => {
  const titleValue = title || 'simple-tab';

  if (arialabel) {
    return {
      id: `${titleValue}-${index}`,
      'aria-labelledby': `${arialabel}-${index}`
    };
  }

  return {
    id: `${titleValue}-${index}`,
    'aria-controls': `${titleValue}panel-${index}`
  };
};

export const capitalizeFirstLetter = (str) => {
  // converting first letter to uppercase
  if (!str) {
    return '';
  }
  const capitalized = str.charAt(0).toUpperCase() + str.slice(1);

  return capitalized;
};

export const updateObj = (obj, newProps) => {
  return { ...obj, ...newProps };
};

export const scrollup = () => {
  window.scrollTo({
    top: 0,
    behavior: 'smooth'
  });
};

export const formatCurrency = (val) => {
  let value = val;
  if (typeof val === 'string') {
    value = typeof parseInt(val, 10) === 'number' ? parseInt(val, 10) : 0;
  }

  const formatter = new Intl.NumberFormat('en-NG', {
    style: 'currency',
    currency: 'NGN'
  });

  return formatter.format(value);
};

export const today = new Date().getFullYear();

export const getColorStatus = (item) => {
  const transStatus = item?.transaction_status;
  const itemType = item?.type;
  const isGateway = !item.type;
  if (isGateway) {
    return transStatus === '1' ? 'completed' : 'declined';
  }
  if (itemType === 'debit' && transStatus === 'declined') {
    return 'declined';
  }
  if (itemType === 'credit' && transStatus === 'completed') {
    return 'completed';
  }
  if (itemType === 'debit' && transStatus === 'completed') {
    return 'declined';
  }
};

export const getTransStatus = (item) => {
  if (item.type) {
    return item.transaction_status;
  }

  return item.transaction_status === '1' ? 'completed' : 'declined';
};

export const isExternal = (link) => {
  const pattern = /^(https?:\/\/.+)/;
  const result = pattern.test(link);
  return result;
};
