/* eslint-disable operator-linebreak */
import axios from './ajax';

// const host = window.location.href;
// const BASE =
//   host.indexOf('localhost') === -1
//     ? host.indexOf('staging') === -1
//       ? 'https://admin.doctordey.aiicomultishield.com/'
//       : 'https://staging-admin.doctordey.aiicomultishield.com/'
//     : 'http://localhost/multishield-admin/';

const BASE = 'https://products-admin.aiicomultishield.com/';

const DATA_PATH = {
  update_extra: {
    type: 'post',
    relPath: 'api/update_enrollee_data'
  },
  get_hospitals: {
    type: 'get',
    relPath: 'pub/plans_info'
  },
  get_products: {
    type: 'get',
    relPath: 'pub/products'
  },
  auth_agent: {
    type: 'post',
    relPath: 'auth/agent'
  },
  load_profile: {
    type: 'get',
    relPath: 'api/dashboard'
  },
  register_enrollee: {
    type: 'post',
    relPath: 'api/register_enrollee'
  },
  verify_email: {
    type: 'get',
    relPath: 'api/check_email'
  },
  init_pay: {
    type: 'post',
    relPath: 'api/init_pay'
  },
  customer_init_pay: {
    type: 'post',
    relPath: 'api/customer_init_pay'
  },
  wallet_details: {
    type: 'get',
    relPath: 'api/wallet_details'
  },
  verify_payment: {
    type: 'get',
    relPath: 'api/payment_verify'
  }
};

export default async function fetch(name, params = false) {
  if (!(name in DATA_PATH)) {
    throw new Error('data path not configured');
  }
  const { type, relPath } = DATA_PATH[name];
  const path = BASE + relPath;
  const result =
    type === 'get'
      ? await axios.get(path, params ? { params } : {})
      : await axios.post(path, params);
  return result;
}

export function get(path) {
  return axios.get(path);
}
