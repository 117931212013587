/* eslint-disable */
import { createSlice } from '@reduxjs/toolkit';
import fetch from 'utility/fetch';
import { updateRequiredTopup, loadPayment } from './payment';
import { updateRegistrationStatus } from './product';

export const profile = createSlice({
  name: 'profile',
  initialState: {
    isAuthenticated: false,
    navigations: {
      Home: '/',
      About: 'https://www.aiicomultishield.com/index.php/home/our-company/about-mission-vision',
      Contact: 'https://www.aiicomultishield.com/index.php/home/contact-us'
    },
    userType: '',
    loading: false,
    notice: {
      type: '',
      message: ''
    },
    profile: {
      details: {},
      dashboard: {},
      plans: [],
      transaction_history: [],
      enrollee: []
    },
    agentNav: {
      Dashboard: '/agent/dashboard',
      'TopUp Wallet': '/agent/wallet/topup',
      Enroll: '/agent/customers/enroll',
      Customers: '/agent/customers',
      'Transaction History': '/agent/transactions'
    },
    defaultNav: {
      Home: '#',
      About: '#',
      Contact: '#'
    }
  },
  reducers: {
    updateProfile: (state, action) => {
      state.profile = action.payload;
    },
    updateUserType: (state, action) => {
      state.userType = action.payload;
    },
    updateIsAuthenticated(state, action) {
      state.isAuthenticated = action.payload;
      if (state.isAuthenticated) {
        state.navigations = { ...state.agentNav };
      }
    },
    updateNotice: (state, action) => {
      state.notice.type = action.payload.type;
      state.notice.message = action.payload.message;
    },
    updateEnrollee: (state, action) => {
      state.profile.enrollee = action.payload.enrollee;
    },
    clearNotice: (state, action) => {
      state.notice.type = '';
      state.notice.message = '';
    },
    logout(state, action) {
      localStorage.clear();
      state.isAuthenticated = false;
      state.navigations = { ...state.defaultNav };
    },
    setLoading(state, action) {
      state.loading = action.payload;
    }
  }
});

export const authenticate = (email, password) => async (dispatch, getState) => {
  dispatch(clearNotice());
  const state = getState();
  const path = state.profile.userType == 'agent' ? 'auth_agent' : 'auth_customer';
  dispatch(setLoading(true));
  try {
    let temp = await fetch(path, { email, password });
    if (temp.data.status) {
      const payload = temp.data.payload;
      if (payload.token && payload.token.length > 0) {
        localStorage.setItem('tktk', payload.token);
        dispatch(updateIsAuthenticated(true));
        dispatch(loadProfile());
      }
    } else {
      const message = temp.data.message ? temp.data.message : 'an error occured';
      dispatch(updateNotice({ type: 'error', message: message }));
    }
  } catch (error) {
    dispatch(updateNotice({ type: 'error', message: 'An error occured' }));
  } finally {
    dispatch(setLoading(false));
  }
};

export const loadProfile = () => async (dispatch, getState) => {
  dispatch(clearNotice());
  const state = getState();
  const tk = localStorage.getItem('tktk');
  if (!tk) {
    dispatch(updateIsAuthenticated(false));
    return;
  }
  const status = tk && tk.length > 0;
  dispatch(updateIsAuthenticated(status));
  const path = 'load_profile';
  dispatch(setLoading(true));
  try {
    let temp = await fetch(path);
    if (temp.data.status) {
      const payload = temp.data.payload;
      dispatch(updateProfile(payload));
    } else {
      const message = temp.data.message ? temp.data.message : 'an error occured';
      // dispatch(updateNotice({ type: 'error', message: message }));
    }
  } catch (error) {
    // if the error is 405 just logout by setting is authenticated to false
    if (error.response.status == 405) {
      dispatch(updateIsAuthenticated(false));
      dispatch(clearNotice());
      return;
    } else {
      dispatch(updateNotice({ type: 'error', message: 'An error occured' }));
    }
  } finally {
    dispatch(setLoading(false));
  }
};

export const registerEnrollee = (enrollee) => async (dispatch, getState) => {
  dispatch(clearNotice());
  dispatch(setLoading(true));
  const path = 'register_enrollee';
  const data = { ...enrollee.customer, ...enrollee.product };
  try {
    let temp = await fetch(path, data);
    if (temp.data.status) {
      dispatch(updateNotice({ type: 'success', message: 'customer enrolled successfully' }));
      dispatch(updateEnrollee(temp.data.payload));
    } else {
      dispatch(updateNotice({ type: 'error', message: temp.data.message }));
    }
  } catch (e) {
    if (e.response.status == 403) {
      let data = e.response.data.payload;
      dispatch(updateRequiredTopup(data.needed_amount));
      dispatch(updateNotice({ type: 'error', message: data.message }));
      return;
    }
    dispatch(updateNotice({ type: 'error', message: e.response.data.payload.message }));
  } finally {
    dispatch(setLoading(false));
  }
};

export const updateExtraInfo = (extraData, customer) => async (dispatch, getState) => {
  dispatch(clearNotice());
  dispatch(setLoading(true));
  const path = 'update_extra';
  const data = { data: extraData, enrollee: customer };
  try {
    let temp = await fetch(path, data);
    if (temp.data.status) {
      dispatch(updateNotice({ type: 'success', message: 'customer enrolled successfully' }));
      dispatch(updateRegistrationStatus(true));
    } else {
      dispatch(updateNotice({ type: 'error', message: temp.data.message }));
    }
  } catch (e) {
    dispatch(updateNotice({ type: 'error', message: e.response.data.message }));
  } finally {
    dispatch(setLoading(false));
  }
};

export const {
  updateProfile,
  updateUserType,
  clearNotice,
  updateNotice,
  checkAuth,
  logout,
  setLoading,
  updateIsAuthenticated,
  updateEnrollee
} = profile.actions;
// export const selectProduct= state => state.product.products;
export default profile.reducer;
