import { lazy } from 'react';

const customerRoutes = [
  {
    path: '',
    element: lazy(() => import('pages/customer/Login')),
    layout: true,
    permission: ''
  },
  {
    path: 'login',
    element: lazy(() => import('pages/customer/Login')),
    layout: true,
    permission: ''
  },
  {
    path: 'dashboard',
    element: lazy(() => import('pages/customer/Dashboard')),
    layout: true,
    permission: ''
  },
  {
    path: 'enroll/:product',
    element: lazy(() => import('pages/customer/Enroll')),
    layout: true,
    permission: ''
  },
  {
    path: 'profile',
    element: lazy(() => import('pages/customer/Profile')),
    layout: true,
    permission: ''
  },
  {
    path: 'subscriptions',
    element: lazy(() => import('pages/customer/Subscriptions')),
    layout: true,
    permission: ''
  }
];

export default customerRoutes;
