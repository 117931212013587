/* eslint-disable */
import { createSlice } from '@reduxjs/toolkit';
import fetch from 'utility/fetch';
import { get } from 'utility/fetch';
import { updateNotice, setLoading, clearNotice } from './profile';

export const payment = createSlice({
  name: 'payment',
  initialState: {
    balance: 0,
    transaction_history: [],
    wallet_history: [],
    balance_required: 0,
    topup_amount: 0,
    payment_status: false,
    paymentSuccess: false,
    paymentError: '',
    emailExist: false,
    isEnrollee: false
  },
  reducers: {
    updateRequiredTopup: (state, action) => {
      state.balance_required = action.payload;
    },
    updatePaymentError: (state, action) => {
      state.paymentError = action.payload;
    },
    updatePaymentHistory: (state, action) => {
      state.transaction_history = action.payload;
    },
    setMailCheckStatus: (state, action) => {
      state.emailExist = action.payload;
    },
    updatePayInfo: (state, action) => {
      state.balance = action.payload.balance;
      state.transaction_history = action.payload.transaction_history;
      state.wallet_history = action.payload.wallet_transaction;
    },
    updatePaymentActionStatus: (state, action) => {
      state.paymentSuccess = action.payload;
    },
    updatePaymentStatus: (state, action) => {
      state.payment_status = action.payload;
    },
    updateBalance(state, action) {
      state.balance = action.payload;
    },
    updateAmount(state, action) {
      state.topup_amount = action.payload;
    },
    updateUserType(state, action) {
      state.isEnrollee = action.payload;
    },
    clearRequiredAmount(state, action) {
      state.balance_required = 0;
    }
  }
});

export const loadPayment = () => async (dispatch, getState) => {
  dispatch(clearNotice());
  const state = getState();
  const path = 'wallet_details';
  dispatch(setLoading(true));
  try {
    let temp = await fetch(path);
    if (temp.data.status) {
      const payload = temp.data.payload;
      sessionStorage.setItem('tktk', payload.token);
      dispatch(updatePayInfo(payload));
    } else {
      const message = temp.data.message ? temp.data.message : 'an error occured';
      dispatch(updateNotice({ type: 'error', message: message }));
    }
  } catch (error) {
    dispatch(updateNotice({ type: 'error', message: 'An error occured' }));
  } finally {
    dispatch(setLoading(false));
  }
};
export const requery =
  (trans_number, reference, setPaystatus = false) =>
  async (dispatch, getState) => {
    dispatch(clearNotice());
    dispatch(setLoading(true));
    const state = getState();
    try {
      const path = 'verify_payment';
      await fetch(path, { trxref: trans_number, reference: reference });
      dispatch(updateNotice({ type: 'success', message: 'payment verified successfully' }));
      if (setPaystatus) {
        dispatch(updatePaymentActionStatus(true));
      }
      if (!state.payment.isEnrollee) {
        dispatch(loadPayment());
      }
    } catch (error) {
      dispatch(updateNotice({ type: 'error', message: 'error occured while verifying email' }));
    } finally {
      dispatch(setLoading(false));
    }
  };

export const checkemail = (email, phone) => async (dispatch, getState) => {
  dispatch(setMailCheckStatus(false));
  dispatch(clearNotice());
  dispatch(setLoading(true));
  try {
    const path = 'verify_email';
    let response = await fetch(path, { phone, email });
    // dispatch(updateNotice({ type: 'success', message: 'payment reverified successfully' }));
    if (response.data.status) {
      setMailCheckStatus(true);
    }
  } catch (error) {
    dispatch(updateNotice({ type: 'error', message: 'error occured while reverifying' }));
  } finally {
    dispatch(setLoading(false));
  }
};

export const initPayment = () => async (dispatch, getState) => {
  dispatch(updatePaymentStatus(false));
  dispatch(clearNotice());
  const state = getState();
  const amount = state.payment.topup_amount;
  if (!amount || amount < 0) {
    dispatch(updateNotice({ type: 'error', message: 'invalid amount' }));
  }
  const path = 'init_pay';
  dispatch(setLoading(true));
  try {
    let response = await fetch(path, { amount });
    if (response.data.status) {
      // setup all the things needed to lauch the inline payment gateway
      let param = response.data.payload;
      let handler = PaystackPop.setup({
        key: param.p_key,
        email: param.email,
        amount: param.amount,
        ref: param.reference,
        onClose: function () {
          dispatch(setLoading(false));
        },
        callback: function (resp) {
          if (resp.status == 'success') {
            let txref = resp.trxref;
            let trans = resp.trans;
            try {
              dispatch(requery(trans, txref, true));
            } catch (error) {
              console.log(error);
            } finally {
              dispatch(setLoading(false));
            }
          }
          // dispatch going to bhe previous page hre
        }
      });
      handler.openIframe();
    } else {
      dispatch(setLoading(false));
      dispatch(updateNotice({ type: 'error', message: response.data.message }));
    }
  } catch (error) {
    console.log(error);
    dispatch(
      updateNotice({ type: 'error', message: 'an error occured while processing operation' })
    );
  } finally {
    dispatch(setLoading(false));
  }
};
export const initCustomerPayment = (customer, product) => async (dispatch, getState) => {
  dispatch(updatePaymentStatus(false));
  dispatch(updateUserType(true));
  dispatch(clearNotice());
  const state = getState();
  const amount = product.fee;
  if (!amount || amount < 0) {
    dispatch(updateNotice({ type: 'error', message: 'An error occured' }));
  }
  const path = 'customer_init_pay';
  dispatch(setLoading(true));
  let response = await fetch(path, { amount, customer, product });
  if (response.data.status) {
    // setup all the things needed to lauch the inline payment gateway
    let param = response.data.payload;
    let handler = PaystackPop.setup({
      key: param.p_key,
      email: param.email,
      amount: param.amount,
      ref: param.reference,
      onClose: function () {
        dispatch(setLoading(false));
      },
      callback: function (resp) {
        if (resp.status == 'success') {
          let txref = resp.trxref;
          let trans = resp.trans;
          try {
            dispatch(requery(trans, txref, true));
          } catch (error) {
            console.log(error);
          } finally {
            dispatch(setLoading(false));
          }
        }
        // dispatch going to bhe previous page hre
      }
    });
    handler.openIframe();
  } else {
    dispatch(setLoading(false));
    dispatch(updateNotice({ type: 'error', message: response.data.message }));
  }
};
export const {
  updateRequiredTopup,
  updatePaymentHistory,
  updateBalance,
  updateAmount,
  updatePayInfo,
  clearRequiredAmount,
  updatePaymentStatus,
  updatePaymentActionStatus,
  setMailCheckStatus,
  updateUserType
} = payment.actions;
// export const selectProduct= state => state.product.products;
export default payment.reducer;
