import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import { AppBar, Button, Container, Drawer, IconButton, Toolbar } from '@mui/material';
import { DRAWER_WIDTH } from 'utility/constants';
import logo from 'assets/images/logo.png';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { clearNotice, logout } from 'store/reducers/profile';
import { isExternal } from 'utility';
import Sidebar from './Sidebar';

const Header = ({ isAuthenticated }) => {
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const navItems = useSelector((state) => state.profile.navigations);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const handleLogout = () => {
    dispatch(clearNotice());
    dispatch(logout());
    navigate('/welcome');
  };

  const container = window ? window.document.body : undefined;

  return (
    <header className="header">
      <Container>
        <AppBar
          component="nav"
          elevation={0}
          color="inherit"
          className="header__nav"
          position="sticky"
        >
          <Toolbar className="p-0">
            <Box variant="h6" sx={{ flexGrow: 1 }}>
              <Link to="/" className="header__logo-link">
                <img src={logo} className="header__logo" />
              </Link>
            </Box>

            {
              <div>
                <Box sx={{ display: { xs: 'none', md: 'block' } }}>
                  {Object.keys(navItems).map((item) => (
                    <Button key={item} className="nav__item">
                      {isExternal(navItems[item]) ? (
                        <a
                          href={navItems[item]}
                          key={item}
                          target="_blank"
                          rel="noreferrer"
                          className="no-link"
                        >
                          {item}
                        </a>
                      ) : (
                        <Link to={navItems[item]} key={item} className="no-link">
                          {item}
                        </Link>
                      )}
                    </Button>
                  ))}
                  {isAuthenticated ? (
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={() => {
                        dispatch(logout());
                        navigate('/agent/login');
                      }}
                      className="nav__item"
                    >
                      Logout
                    </Button>
                  ) : (
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={() => navigate('/welcome')}
                      className="nav__item"
                    >
                      Login
                    </Button>
                  )}
                </Box>

                <IconButton
                  color="secondary"
                  className="fs-lg"
                  aria-label="open drawer"
                  edge="start"
                  onClick={handleDrawerToggle}
                  sx={{ display: { md: 'none' } }}
                >
                  <span className="icon-menu" />
                </IconButton>
              </div>
            }
          </Toolbar>
        </AppBar>
      </Container>

      <Box component="nav">
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', md: 'none' },
            '& .MuiDrawer-paper': {
              boxSizing: 'border-box',
              width: DRAWER_WIDTH
            }
          }}
        >
          <Sidebar
            isAuthenticated={isAuthenticated}
            handleLogout={handleLogout}
            navigations={navItems}
            handleDrawerToggle={handleDrawerToggle}
          />
        </Drawer>
      </Box>
    </header>
  );
};

Header.propTypes = {
  isAuthenticated: PropTypes.bool
};

export default Header;
