import React from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Link,
  List,
  ListItem,
  Typography
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { FOOTER_NAV } from 'utility/constants';
import { slugify } from 'utility';

const FooterAccordion = () => {
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  const getId = (text) => {
    return {
      id: `footer-${slugify(text)}`,
      content: `${slugify(text)}-content`
    };
  };

  return (
    <Box className="footer__mb footer__nav" sx={{ display: { xs: 'block', sm: 'none' } }}>
      {FOOTER_NAV.map((item, i) => (
        <Accordion
          key={i}
          elevation={0}
          expanded={expanded === slugify(item.title)}
          onChange={handleChange(slugify(item.title))}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon className="fs-lg" color="primary" />}
            aria-controls={getId(item.title).content}
            id={getId(item.title).id}
            className="p-0"
          >
            <Typography variant="h6" color="primary" className="fs-sm">
              {item.title.toUpperCase()}
            </Typography>
          </AccordionSummary>
          <AccordionDetails className="p-0">
            <List className="footer__list">
              {item.list.map((navItem, j) => (
                <ListItem key={j}>
                  <Link href={navItem.url}>{navItem.name}</Link>
                </ListItem>
              ))}
            </List>
          </AccordionDetails>
        </Accordion>
      ))}
    </Box>
  );
};

export default FooterAccordion;
