import Profile from './profile';
import Product from './product';
import Payment from './payment';

export default {
  reducer: {
    profile: Profile,
    product: Product,
    payment: Payment
  }
};
