import { CircularProgress, Stack } from '@mui/material';
import React from 'react';

const Loader = () => {
  return (
    <Stack direction="row" alignItems="center" justifyContent="center" sx={{ paddingY: 2 }}>
      <CircularProgress />
    </Stack>
  );
};

export default Loader;
