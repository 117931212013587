import React from 'react';
import { Box, Container } from '@mui/material';
import FooterGrid from './FooterGrid';
import ContactInfo from './ContactInfo';
import Copyright from './Copyright';
import FooterAccordion from './FooterAccordion';

const Footer = () => {
  return (
    <Box component="footer">
      <Container>
        <FooterGrid />
        <FooterAccordion />
        <ContactInfo />
      </Container>

      <Copyright />
    </Box>
  );
};

export default Footer;
