import { Box, Grid, Link, Stack, Typography } from '@mui/material';
import React from 'react';
import { Facebook, Instagram, LinkedIn, Twitter } from '@mui/icons-material';

const ContactInfo = () => {
  return (
    <Grid container spacing={2} className="footer__mb footer__contact">
      <Grid item xs={12} sm={4}>
        <Typography component="p" color="primary">
          322, Ikorodu Road, Anthony B/Stop, <br />
          Anthony Village, Lagos.
        </Typography>
      </Grid>

      <Grid item xs={12} sm={4}>
        <Typography component="p" color="primary">
          <Link href="tel:+23412930823">01-2930823</Link> <br />
          <Link href="tel:+23470026744353">0700AMSHIELD (070026744353)</Link>
        </Typography>
      </Grid>

      <Grid item xs={12} sm={4}>
        <Typography component="p" color="primary" className="mb-3">
          <Link href="mailto:info@aiicomultishield.com">info@aiicomultishield.com</Link>
        </Typography>

        <Stack direction="row" spacing={2}>
          <Box
            component="a"
            href="https://www.facebook.com/multishieldltd"
            className="footer__social"
          >
            <Facebook />
          </Box>

          <Box component="a" href="https://twitter.com/multishield01" className="footer__social">
            <Twitter />
          </Box>

          <Box
            component="a"
            href="https://www.instagram.com/aiicomultishield/"
            className="footer__social"
          >
            <Instagram />
          </Box>

          <Box
            component="a"
            href="https://ng.linkedin.com/company/aiicomultishield"
            className="footer__social"
          >
            <LinkedIn />
          </Box>
        </Stack>
      </Grid>
    </Grid>
  );
};

export default ContactInfo;
